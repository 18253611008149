





















































































/* eslint-disable @typescript-eslint/camelcase */

import {
  Vue, Component, Watch,
} from 'vue-property-decorator';
import {
  CreateOrderActions, CreateOrderData, loadScript,
  OnApproveActions, OnApproveData, OrderResponseBody,
  PayPalNamespace, PayPalScriptOptions, PurchaseItem, PurchaseUnit,
} from '@paypal/paypal-js';
import MyHive from '@/components/MyHive.vue';
import { Product, User } from '@/types';
import { getFunctions, httpsCallable } from 'firebase/functions';

@Component({
  components: {
    MyHive,
  },
})
export default class BuyCreditsDialog extends Vue {
  dialog = false

  loading = false
  updating = false

  alert = false
  error = ''

  hivesCredits = 0
  honeycombsCredits = 0

  paypal: PayPalNamespace | null = null;

  headers = [
    {
      text: 'Prodotto',
      align: 'start',
      sortable: false,
      value: 'product',
    },
    {
      text: 'Quantità',
      align: 'end',
      sortable: false,
      value: 'quantity',
    },
    {
      text: 'Prezzo',
      align: 'end',
      sortable: false,
      value: 'price',
    },
  ]

  get value(): boolean {
    return this.$store.getters.buyCreditsDialog;
  }

  get currentUser(): User {
    return this.$store.getters.user;
  }

  get currentUserId(): string | null {
    return this.currentUser.uid ?? null;
  }

  @Watch('value')
  onValueChanged(val: boolean) {
    console.log(`${this.constructor.name}: onValueChanged()`); // eslint-disable-line no-console

    if (val) {
      this.loading = false;
      this.updating = false;
      this.alert = false;
      this.error = '';
    }

    this.dialog = val;
  }

  @Watch('dialog')
  async onDialogChanged(val: boolean) {
    console.log(`${this.constructor.name}: onDialogChanged()`); // eslint-disable-line no-console

    if (this.value !== val) {
      this.$store.dispatch('toggleDialog', 'buyCredits');
    }

    if (val) {
      // Dialog aperto

      // Se l'SDK di PayPal non è stato ancora caricata...
      if (!this.paypal) {
        // ... inizializza i servizi di PayPal
        this.initPayPal();
      }

      // Scrolla in cima alla finestra
      // Questo metodo funziona solo per le dialog scrollabili
      this.$nextTick(() => {
        const element = document.querySelector('.v-dialog--active > .v-card > .v-card__text');
        if (element) this.$vuetify.goTo(0, { container: element as HTMLElement, duration: 0 });
      });
    }
  }

  get isCartEmpty() { return this.$store.getters.isCartEmpty; }

  get cartItems(): Product[] { return this.$store.getters.cartItems; }

  get purchaseUnits(): PurchaseUnit[] {
    const result: PurchaseUnit[] = [
      {
        amount: {
          value: `${this.totalValue}`,
          currency_code: 'EUR',
          breakdown: {
            item_total: {
              value: `${this.totalValue}`,
              currency_code: 'EUR',
            },
          },
        },
      },
    ];

    const items: PurchaseItem[] = [];

    this.cartItems.forEach((product: Product) => {
      items.push({
        sku: product.code,
        name: product.title,
        description: product.descr,
        quantity: `${product.quantity ?? 0}`,
        category: 'DIGITAL_GOODS',
        unit_amount: {
          value: `${product.price}`,
          currency_code: 'EUR',
        },
      });
    });

    result[0].items = items;

    return result;
  }

  get totalValue(): number {
    let tot = 0;

    this.cartItems.forEach((p: Product) => {
      tot += p.price * (p.quantity ?? 0);
    });

    return tot;
  }

  get total() {
    const formatter = new Intl.NumberFormat('it-IT', {
      style: 'currency',
      currency: 'EUR',
    });

    return formatter.format((this as any).totalValue);
  }

  price(product: Product) {
    const formatter = new Intl.NumberFormat('it-IT', {
      style: 'currency',
      currency: 'EUR',
    });

    return formatter.format(product.price * (product.quantity || 0));
  }

  async initPayPal() {
    const methodName = this.constructor.name;

    console.log(`${methodName}: initPayPal()`); // eslint-disable-line no-console

    // Carica l'SDK di PayPal
    try {
      this.paypal = await loadScript({
        'client-id': this.$paypalClientId,
        currency: 'EUR',
        locale: 'it_IT',
        'disable-funding': 'sofort,mybank',
        debug: false,
      } as PayPalScriptOptions);
    } catch (error) {
      console.log(`${methodName}: initPayPal() - Failed to load the PayPal JS SDK script, error =`, error); // eslint-disable-line no-console
    }

    // Se l'SDK di PayPal è stata caricata correttamente...
    if (this.paypal?.Buttons && this.$refs.paypal) {
      console.log(`${methodName}: initPayPal() - Inizializza i pulsanti`); // eslint-disable-line no-console

      // ... inizializza i pulsanti
      try {
        await this.paypal.Buttons({
          createOrder: (data: CreateOrderData, actions: CreateOrderActions) => {
            console.log(`${methodName}: initPayPal() - createOrder()`); // eslint-disable-line no-console

            console.log(`${methodName}: initPayPal() - createOrder() data =`, data); // eslint-disable-line no-console
            console.log(`${methodName}: initPayPal() - createOrder() actions =`, actions); // eslint-disable-line no-console

            return actions.order.create({
              intent: 'CAPTURE',

              purchase_units: this.purchaseUnits,

              application_context: {
                brand_name: 'Mielopolis',
                locale: 'it-IT',
                shipping_preference: 'NO_SHIPPING',
              },
            });
          },

          onApprove: async (data: OnApproveData, actions: OnApproveActions) => {
            console.log(`${methodName}: initPayPal() - onApprove()`); // eslint-disable-line no-console

            console.log(`${methodName}: initPayPal() - onApprove() data =`, data); // eslint-disable-line no-console
            console.log(`${methodName}: initPayPal() - onApprove() actions =`, actions); // eslint-disable-line no-console

            // TODO: Possibile che order sia undefined?
            if (!actions.order) return;

            this.loading = true;
            this.updating = true;

            const order: OrderResponseBody = await actions.order.capture();
            console.log(`${methodName}: initPayPal() - onApprove() order =`, order); // eslint-disable-line no-console

            await this.onOrder(order);

            this.loading = false;
            this.updating = false;

            this.dialog = false;
            this.$store.dispatch('openDialog', 'myCredits');
          },

          onError: (err: Record<string, unknown>) => {
            console.log(`${methodName}: initPayPal() - onError() error =`, err); // eslint-disable-line no-console
          },
        }).render(this.$refs.paypal as HTMLElement);
      } catch (error) {
        console.log(`${methodName}: initPayPal() - Failed to render the PayPal Buttons, error =`, error); // eslint-disable-line no-console
      }
    }
  }

  onCancel() {
    console.log(`${this.constructor.name}: onCancel()`); // eslint-disable-line no-console

    this.dialog = false;
  }

  async onOrder(order: OrderResponseBody) {
    console.log(`${this.constructor.name}: onOrder()`); // eslint-disable-line no-console

    const functions = getFunctions();
    const createOrder = httpsCallable(functions, 'createOrder');

    const result = await createOrder({
      uid: this.currentUserId,
      order,
    });

    console.log(`${this.constructor.name}: onOrder() result =`, result); // eslint-disable-line no-console

    return Promise.resolve();
  }
}
