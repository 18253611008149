












































































import {
  Adoption, Apiary, Hive, User,
} from '@/types';
import {
  Vue, Component, Prop,
} from 'vue-property-decorator';
import Progress from '@/components/Progress.vue';
import ImageWithLabel from '@/components/ImageWithLabel.vue';
import moment from 'moment';

moment.locale('it');

@Component({
  components: {
    Progress,
    ImageWithLabel,
  },
})
export default class MyHive extends Vue {
  @Prop(String) readonly apiaryId: string | undefined
  @Prop(String) readonly hiveId: string | undefined
  @Prop(String) readonly adoptionId: string | undefined

  loading = true
  error = false

  adoption: Adoption | null = null
  hive: Hive | null = null

  async mounted() {
    console.log(`${this.constructor.name}: mounted()`); // eslint-disable-line no-console

    // Se è stato specificato l'id di un'adozione...
    if (this.adoptionId) {
      // ... recupera l'adozione corrispondente
      this.adoption = await this.$store.dispatch('getAdoptionById', {
        id: this.adoptionId,
      });

      if (!this.adoption) {
        console.log(`${this.constructor.name}: mounted() - Non riesco a trovare l'adozione corrispondente all'id ${this.adoptionId}`); // eslint-disable-line no-console

        this.error = true;
        this.loading = false;
        return;
      }

      // Ottienie l'id dell'apiario e dell'arnia dall'adozione
      // e li utilizza per recuperare i dettagli sull'arnia adottata
      this.hive = await this.$store.dispatch('getHiveById', {
        apiaryId: this.adoption.apiaryId,
        hiveId: this.adoption.hiveId,
      });

      this.loading = false;
    } else {
      if (!this.apiaryId || !this.hiveId) {
        console.log(`${this.constructor.name}: mounted() - Sia apiaryId che hiveId vanno definiti se non è definito adoptionId`); // eslint-disable-line no-console

        this.error = true;
        this.loading = false;

        return;
      }

      // Recupera i dettagli sull'arnia adottata
      this.hive = await this.$store.dispatch('getHiveById', {
        apiaryId: this.apiaryId,
        hiveId: this.hiveId,
      });

      if (!this.hive) {
        console.log(`${this.constructor.name}: mounted() - Non riesco a trovare l'Hive corrispondente a apiaryId = ${this.apiaryId} e hiveId = ${this.hiveId}`); // eslint-disable-line no-console

        this.error = true;
        this.loading = false;

        return;
      }

      // Deduce l'id dell'adozione e recupera i suoi dettagli
      const adoptionId = `${this.currentUser.uid}${this.apiaryId}${this.hiveId}`;
      this.adoption = await this.$store.dispatch('getAdoptionById', {
        id: adoptionId,
      });

      if (!this.adoption) {
        console.log(`${this.constructor.name}: mounted() - Non riesco a trovare l'Adoption corrispondente all'id ${adoptionId}`); // eslint-disable-line no-console

        this.error = true;
        this.loading = false;

        return;
      }

      this.loading = false;
    }
  }

  get functionsURL() {
    return this.$firebaseFunctionsURL;
  }

  get currentUser(): User {
    return this.$store.getters.user;
  }

  get apiaries(): Apiary[] {
    return this.$store.getters.apiaries
      .filter((a: Apiary) => a.enabled !== false);
  }

  get apiary(): Apiary | null {
    return this.apiaries.find((a) => a.id === this.apiaryId) ?? null;
  }

  get hiveImage(): string {
    return this.hive?.photo
      ? `/hives/${this.hive.photo}`
      : '/apiaries/fake-header-01.jpg';
  }

  get hiveName(): string {
    return this.hive?.name ?? '';
  }

  get hivePopulation(): string {
    return this.hive?.population ?? 'Sconosciuta';
  }

  get hiveBehavior(): string {
    return this.hive?.behavior ?? 'Sconosciuto';
  }

  get hiveProductivity(): string {
    return this.hive?.productivity ?? 'Sconosciuta';
  }

  get apiaryName(): string {
    return this.apiary?.name ?? '';
  }

  get apiaryLocation(): string {
    return this.apiary?.location ?? 'Sconosciuta';
  }

  get percent(): number {
    return 100 * ((this.adoptionLength - this.adoptionDaysLeft) / this.adoptionLength);
  }

  get progressText(): string {
    return `${this.adoptionDaysLeft} giorni al termine`;
  }

  get progressColor(): string {
    return this.isEnding ? '#D15555' : '#F4BC46';
  }

  get isEnding(): boolean {
    return this.adoptionDaysLeft < 30;
  }

  get adoptionStartDate(): string {
    return this.mDate(this.adoption?.startDate.toDate()) ?? '-';
  }

  get adoptionLength(): number {
    return this.adoption ? moment(this.adoption.endDate.toDate()).diff(moment(this.adoption.startDate.toDate()), 'days') : 0;
  }

  get adoptionDaysLeft(): number {
    return this.adoption ? moment(this.adoption.endDate.toDate()).diff(moment(new Date()), 'days') : 0;
  }

  mDate(date: Date | null | undefined): string | null { return date ? moment(date).format('L') : null; }

  onApiary() {
    if (!this.apiaryId) return;

    this.$router.push({
      name: 'Apiary',
      params: {
        id: this.apiaryId,
      },
    }).catch(() => {
      this.$emit('close');
    });
  }

  onCertificate() {
    const url = `${this.functionsURL}downloadPDF?u=${this.currentUser.uid}&a=${this.apiaryId}&h=${this.hiveId}`;

    // eslint-disable-next-line no-restricted-globals
    window.open(url, '_blank');
  }
}
