







































import {
  Vue, Component, Prop,
} from 'vue-property-decorator';

@Component
export default class ImageWithLabel extends Vue {
  @Prop({ type: String, default: null }) readonly title: string | null | undefined
  @Prop({ type: String, default: null }) readonly src: string | null | undefined
  @Prop({ type: String, default: null }) readonly alt: string | null | undefined
  @Prop({ type: String, default: '#F4BC46' }) readonly color!: string
  @Prop({ type: Boolean, default: false }) readonly loading!: boolean
  @Prop({ type: Number, default: 100 }) readonly width!: number
  @Prop({ type: Number, default: 130 }) readonly height!: number
  @Prop({ type: Number, default: 150 }) readonly mdWidth!: number
  @Prop({ type: Number, default: 150 }) readonly mdHeight!: number
}
